<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading" class="content-box">

  <div class="page-header">
    <h2 translate>User details</h2>
  </div>

  <ul ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
    <li ngbNavItem="user_details_info_tab" id="user_details_info_tab">
      <a ngbNavLink>
        <span translate>INFORMATION</span>
      </a>
      <ng-template ngbNavContent>
        <app-user-details-information></app-user-details-information>
      </ng-template>
    </li>
    <li *ngIf="messagingResources"
        ngbNavItem="user_details_subscriptions_tab"
        id="user_details_subscriptions_tab">
      <a ngbNavLink>
        <span translate>SUBSCRIPTIONS</span>
      </a>
      <ng-template ngbNavContent>
        <app-user-details-subscriptions [tabSet]="tabSet"></app-user-details-subscriptions>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
