<div class="modal-header">
  <h4 class="modal-title strong">
    <a><i id="close_modal_link" class="fa fa-times ml-3" (click)="close()"></i></a>
    <span translate>Create new link</span>
    <app-content-language [languageCodes]="languageCodes" class="float-right mb-2"></app-content-language>
  </h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <form [formGroup]="externalReferenceForm" #form="ngForm">
        <app-localizable-input-with-language-indicator-for-all-languages [id]="'link_title_input'"
                                                                         [label]="'Link title' | translate"
                                                                         [infoText]="'INFO_TEXT_LINK_TITLE'"
                                                                         [htmlIdentifierPrefix]="'link_title_input'"
                                                                         [parentElementsLanguageCodes]="languageCodes"
                                                                         [formControlName]="'title'"></app-localizable-input-with-language-indicator-for-all-languages>

        <app-localizable-textarea-with-language-indicator-for-all-languages [id]="'link_description_textarea'"
                                                                            [label]="'Description' | translate"
                                                                            [infoText]="'INFO_TEXT_LINK_DESCRIPTION'"
                                                                            [htmlIdentifierPrefix]="'link_description_input'"
                                                                            [parentElementsLanguageCodes]="languageCodes"
                                                                            [formControlName]="'description'"></app-localizable-textarea-with-language-indicator-for-all-languages>

        <app-literal-input [id]="'link_url_input'"
                           [label]="'URL' | translate"
                           [required]="true"
                           required
                           [infoText]="'INFO_TEXT_LINK_URL'"
                           [formControlName]="'href'"></app-literal-input>

        <app-property-type-select id="propertytype_select"
                                  [label]="'Link type' | translate"
                                  [context]="'ExternalReference'"
                                  [infoText]="'INFO_TEXT_LINK_TYPE'"
                                  [formControlName]="'propertyType'"></app-property-type-select>
      </form>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div>
    <button id="create_link_button" type="button" class="btn btn-action" [disabled]="!form.valid" (click)="add()" translate>Add</button>
    <button id="cancel_modal_button" type="button" class="btn btn-link" (click)="close()" translate>Cancel</button>
  </div>
</div>
