<div class="content-box">
  <div class="main">
    <div>
      <h1 class="float-left" translate>Create registry</h1>
    </div>

    <div class="float-right">
      <app-editable-buttons [form]="form"></app-editable-buttons>
      <app-content-language [isSomeRegistryPage]="true"></app-content-language>
    </div>
  </div>

  <form [formGroup]="codeRegistryForm" #form="ngForm">
    <div class="row">
      <div class="col-lg-4 col-md-5">
        <app-literal-input [id]="'registry_value_input'"
                           [label]="'Registry ID' | translate"
                           [required]="true"
                           [infoText]="'INFO_TEXT_REGISTRY_VALUE'"
                           [formControlName]="'codeValue'"></app-literal-input>

        <div class="form-group">
          <app-error-messages id="registry_form_error_messages" [control]="codeRegistryForm"></app-error-messages>
        </div>

        <app-localizable-input-with-language-indicator [id]="'registry_name_input'"
                               [label]="'Registry name' | translate"
                               [infoText]="'INFO_TEXT_REGISTRY_NAME'"
                               [required]="true"
                               [formControlName]="'prefLabel'"></app-localizable-input-with-language-indicator>



      </div>

      <div class="col-lg-4 col-md-5">
        <app-organizations-input id="registry_organizations_input"
                                 [label]="'Organization' | translate"
                                 [required]="true"
                                 [infoText]="'INFO_TEXT_REGISTRY_ORGANIZATION'"
                                 [formControlName]="'organizations'"></app-organizations-input>

        <app-localizable-textarea-with-language-indicator [id]="'registry_description_textarea'"
                                                          [label]="'Description' | translate"
                                                          [infoText]="'INFO_TEXT_REGISTRY_DESCRIPTION'"
                                                          [formControlName]="'description'"></app-localizable-textarea-with-language-indicator>
      </div>
    </div>
  </form>
</div>
