<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading">
  <form [formGroup]="memberForm" #form="ngForm">

    <div class="row">
      <div class="col-5">
        <app-literal [label]="'Code list' | translate"
                     [value]="extension.parentCodeScheme.getDisplayName(languageService)"></app-literal>

        <app-literal [label]="'Extension' | translate"
                     [value]="extension.getDisplayName(languageService)"></app-literal>

        <app-localizable-input-with-language-indicator-for-all-languages [id]="'member_name_input'"
                                                                         [htmlIdentifierPrefix]="'member_name_input'"
                                                                         [parentElementsLanguageCodes]="extension.parentCodeScheme.languageCodes"
                                                                         [label]="'Member name' | translate"
                                                                         [formControlName]="'prefLabel'"></app-localizable-input-with-language-indicator-for-all-languages>

        <div *ngFor="let valueType of valueTypes">
          <app-literal-input [id]="'member_membervalue_' + valueType.localName + '_input'"
                             [label]="valueType.prefLabel | translateValue"
                             [required]="valueType.required"
                             [formControlName]="valueType.localName"></app-literal-input>
        </div>

        <app-inline-clipboard *ngIf="getCodeUri()"
                              id="member_code_uri"
                              [label]="'Code URI' | translate"
                              [value]="getCodeUri()"
                              [showAsLink]="false"
                              [infoText]="'INFO_TEXT_CODE_URI'"></app-inline-clipboard>

        <app-inline-clipboard *ngIf="getCodeConceptUri()"
                              id="member_code_terminology_uri"
                              [label]="'Code vocabularies URI' | translate"
                              [value]="getCodeConceptUri()"
                              [showAsLink]="false"
                              [infoText]="'INFO_TEXT_CODE_CONCEPT_URI'"></app-inline-clipboard>

        <app-code-input id="member_code_input"
                        [label]="'Code' | translate"
                        [codeSchemes]="this.allCodeSchemes"
                        [required]="true"
                        [showDetailLabel]="showCodeDetailLabel"
                        [formControlName]="'code'"
                        [infoText]="'INFO_TEXT_CHOOSE_CODE_FOR_THIS_MEMBER'"></app-code-input>
      </div>

      <div class="col-5">
        <app-member-input id="member_relatedmember_input"
                          *ngIf="!isCodeExtension"
                          [label]="labelForTheHiearchicalBroaderCodeChoice | translate"
                          [codeSchemes]="this.allCodeSchemes"
                          [extension]="member.extension"
                          [currentMember]="member"
                          [formControlName]="'relatedMember'"
                          [infoText]="infoTextForHierarchicalParentInfoButtonBased"></app-member-input>

        <app-date-range-input id="member_validity_input"
                              [label]="'Validity' | translate"
                              [infoText]="'INFO_TEXT_MEMBER_VALIDITY'"
                              [formControlName]="'validity'"></app-date-range-input>

        <app-inline-clipboard id="member_uri"
                              [label]="'URI' | translate"
                              [value]="getMemberUri()"
                              [showAsLink]="false"
                              [infoText]="'INFO_TEXT_URI_MEMBER'"></app-inline-clipboard>

        <app-literal *ngIf="user"
                     [id]="'member_user'"
                     [label]="'Modified by' | translate"
                     [value]="user.getDisplayName()"
                     [infoText]="'INFO_TEXT_LAST_MODIFICATION_USER'"></app-literal>

        <app-literal [id]="'member_last_modification'"
                     [label]="'Last modification' | translate"
                     [value]="member.modifiedDisplayValue"></app-literal>
      </div>

      <div class="col-2">
        <app-editable-buttons [form]="form"
                              [entity]="member"></app-editable-buttons>
      </div>
    </div>

  </form>
</div>
