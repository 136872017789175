<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading" class="content-box">
  <div class="main">
    <div>
      <h1 class="float-left" translate>Create member</h1>
    </div>


    <div class="float-right">
      <app-content-language [languageCodes]="codeScheme.languageCodes"></app-content-language>
    </div>
  </div>

  <form [formGroup]="memberForm" #form="ngForm">

    <div class="row">
      <div class="col-12">
        <app-editable-buttons [form]="form"
                              [entity]="extension"></app-editable-buttons>
      </div>
    </div>

    <div class="row metabar">
      <div class="col-12">
        <div class="metaitem">
          <span translate>Registry</span><span>: {{extension.parentCodeScheme.codeRegistry.getDisplayName(languageService)}}</span>
        </div>
        <div class="metaitem">
          <span translate>Code list</span><span>: {{extension.parentCodeScheme.getDisplayName(languageService)}}</span>
        </div>
        <div class="metaitem" *ngIf="extension.parentCodeScheme.mainOrganizations.length > 0">
          <span translate>Organization</span><span>: </span>
          <span *ngFor="let organization of extension.parentCodeScheme.mainOrganizations; let last = last">{{organization.prefLabel | translateValue:true}}{{last ? '' : ', '}}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-5">
        <div class="form-group">
          <app-literal [label]="'Extension' | translate"
                       [value]="extension.getDisplayName(languageService)"></app-literal>

          <app-error-messages id="member_form_error_messages" [control]="memberForm"></app-error-messages>

          <app-localizable-input-with-language-indicator-for-all-languages [id]="'member_name_input'"
                                                                           [htmlIdentifierPrefix]="'member_name_input'"
                                                                           [label]="'Member name' | translate"
                                                                           [parentElementsLanguageCodes]="extension.parentCodeScheme.languageCodes"
                                                                           [formControlName]="'prefLabel'"></app-localizable-input-with-language-indicator-for-all-languages>

          <div *ngFor="let valueType of valueTypes">
            <app-literal-input [id]="'member_membervalue_' + valueType.localName + '_input'"
                               [label]="valueType.prefLabel | translateValue"
                               [required]="valueType.required"
                               [formControlName]="valueType.localName"></app-literal-input>
          </div>

          <app-inline-clipboard *ngIf="getCodeUri()"
                                id="member_code_uri"
                                [label]="'Code URI' | translate"
                                [value]="getCodeUri()"
                                [showAsLink]="false"
                                [infoText]="'INFO_TEXT_CODE_URI'"></app-inline-clipboard>
        </div>
      </div>

      <div class="col-lg-4 col-md-5">
        <app-inline-clipboard *ngIf="getCodeConceptUri()"
                              id="member_code_terminology_uri"
                              [label]="'Code vocabularies URI' | translate"
                              [value]="getCodeConceptUri()"
                              [showAsLink]="false"
                              [infoText]="'INFO_TEXT_CODE_CONCEPT_URI'"></app-inline-clipboard>

        <app-code-input id="member_code_input"
                        [label]="'Code' | translate"
                        [codeSchemes]="this.allCodeSchemes"
                        [required]="true"
                        [showDetailLabel]="showCodeDetailLabel"
                        [formControlName]="'code'"
                        [infoText]="'INFO_TEXT_CHOOSE_CODE_FOR_THIS_MEMBER'"></app-code-input>

        <app-member-input *ngIf="!isCodeExtension"
                          id="member_relatedmember_input"
                          [label]="labelForTheHiearchicalBroaderCodeChoice | translate"
                          [codeSchemes]="this.allCodeSchemes"
                          [extension]="extension"
                          [formControlName]="'relatedMember'"
                          [infoText]="infoTextForHierarchicalParentInfoButtonBased"></app-member-input>

        <app-date-range-input id="member_validity_input"
                              [label]="'Validity' | translate"
                              [infoText]="'INFO_TEXT_MEMBER_VALIDITY'"
                              [formControlName]="'validity'"></app-date-range-input>
      </div>

    </div>
  </form>
</div>
