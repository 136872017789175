<div class="modal-header">
  <h4 class="modal-title strong">
    <a><i id="close_modal_link" class="fa fa-times ml-3" (click)="close()"></i></a>
    <span translate>Show link</span>
    <app-content-language [languageCodes]="languageCodes" class="float-right mb-2"></app-content-language>
  </h4>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-md-12">
      <div *ngIf="externalReference.image" class="mb-4">
        <a id="external_ref_image_link" [href]="externalReference.href" target="_blank" rel="noopener noreferrer">
          <img class="link-image" [src]="externalReference.image" title="{{externalReference.title | translateValue}}"/>
        </a>
      </div>

      <app-localizable-literal-with-language-indicator-for-all-languages [label]="'Link title' | translate"
                                                                         [parentElementsLanguageCodes]="languageCodes"
                                                                         [value]="externalReference.title"></app-localizable-literal-with-language-indicator-for-all-languages>

      <app-localizable-literal-with-language-indicator-for-all-languages [label]="'Description' | translate"
                                                                         [parentElementsLanguageCodes]="languageCodes"
                                                                         [value]="externalReference.description"></app-localizable-literal-with-language-indicator-for-all-languages>

      <dt><label translate>URL</label></dt>
      <dd>
        <div class="text-content-wrap"><a id="external_ref_url_link" [href]="externalReference.href"
                                          target="_blank" rel="noopener noreferrer">{{externalReference.href}}</a></div>
      </dd>

      <app-localizable-literal-with-language-indicator-for-all-languages *ngIf="externalReference.propertyType != null"
                                                                         [label]="'Link type' | translate"
                                                                         [value]="externalReference.propertyType.prefLabel"></app-localizable-literal-with-language-indicator-for-all-languages>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div>
    <button id="close_modal_button" type="button" class="btn btn-action" (click)="close()" translate>Ok</button>
  </div>
</div>
