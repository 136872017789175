<div class="modal-header">
  <h4 class="modal-title">
    <a><i id="close_modal_link" class="fa fa-times" (click)="cancel()"></i></a>
    <div>
      <span translate>{{terminologyIntegrationModalPageTitle}}</span>
    </div>
  </h4>
</div>

<div class="modal-body full-height">
  <div class="modal-sections">
    <div class="fixed-height-section pb-2">
      <p translate>{{terminologyIntegrationModalInstructionText}}</p>
      <div class="input-group input-group-lg input-group-search">
        <input class="form-control"
               id="search_concept_input"
               #searchInput
               type="text"
               placeholder="{{'Search for concept' | translate}}"
               [(ngModel)]="search"/>
      </div>
    </div>
    <div class="fixed-height-section pb-2">
      <app-filter-dropdown class="d-inline-block" style="padding-right: 5px"
                           id="vocabulary_selection_dropdown"
                           [filterSubject]="vocabulary$"
                           [options]="vocabularyOptions"></app-filter-dropdown>
      <app-filter-dropdown class="d-inline-block" style="padding-right: 5px"
                           id="status_selection_dropdown"
                           [filterSubject]="status$"
                           [options]="statusOptions"></app-filter-dropdown>
      <app-filter-dropdown class="d-inline-block"
                           id="language_selection_dropdown"
                           [filterSubject]="language$"
                           [options]="languageOptions"></app-filter-dropdown>
    </div>
    <div class="pb-2">
      <span *ngIf="meta != null && meta.totalResults > meta.resultCount" id='search_concept_large_result_text'>{{
        'CONCEPT_SEARCH_MATCHED_RESULTS_LARGE' |
        translate : {
        count: meta.totalResults,
        showCount: 200
      } }}</span>
      <span *ngIf="meta != null && meta.totalResults <= meta.resultCount" id='search_concept_result_text'>{{ 'CONCEPT_SEARCH_MATCHED_RESULTS' | translate : {
        count: meta.totalResults
      } }}</span>
    </div>
    <div class="content-box scrolling-full-height">
      <div *ngIf="!loading && hasSearchResults()" class="search-results">
        <div class="search-result"
             *ngFor="let concept of searchResults; let last = last"
             [id]="concept.getIdIdentifier(languageService) + '_concept_link'"
             (click)="select(concept)">
          <div class="content" [class.last]="last">
            <span class="float-right"><app-status class="status" [status]="concept.status"></app-status></span>
            <span class="title">{{concept.prefLabel | translateValue:false:chosenLanguageCodeValue }}</span>
            <span class="body">{{concept.definition | translateValue:false:chosenLanguageCodeValue}}</span>
            <div class="origin">
              <span class="float-left">{{concept.vocabularyPrefLabel | translateValue:false:chosenLanguageCodeValue }}</span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!loading && !hasSearchResults()">
        <p translate class="no-results content last">No search results</p>
      </div>
      <div *ngIf="loading">
        <app-ajax-loading-indicator></app-ajax-loading-indicator>
      </div>
    </div>
    <div class="pb-2" *ngIf="!loading && (debouncedSearch$ | async)">
      <p translate style="padding-top: 10px">Concept suggestion instruction text</p>
      <dl>
        <dd>
          <div>
            <button id="open_terminology_modal_button_for_concept_suggestion"
                    type="button"
                    class="btn btn-sm btn-secondary-action mt-2 mr-2"
                    (click)="suggestConcept()" [disabled]="!canSuggestConcept()" translate>Suggest the concept to Terminologies</button>
            <button id="cancel_modal_button_inline"
                    type="button"
                    class="btn btn-sm btn-secondary-action mt-2"
                    (click)="cancel()">{{cancelText | translate}}</button>
          </div>
        </dd>
      </dl>
    </div>
  </div>
</div>

<div class="modal-footer" *ngIf="!search">
  <button id="cancel_modal_button"
          type="button"
          class="btn btn-sm btn-secondary-action mt-2"
          (click)="cancel()">{{cancelText | translate}}</button>
</div>
