<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading" class="content-box">
  <div class="main">
    <div>
      <h1 class="text-content-wrap">{{codeRegistry.getDisplayName(languageService)}}</h1>
    </div>

    <div class="float-right">
      <div *ngIf='this.canDelete' ngbDropdown class="d-inline-block float-right ml-3" placement="bottom-right">
        <button class="btn btn-action" id="registryDropdown" ngbDropdownToggle translate>Choose action</button>
        <div ngbDropdownMenu aria-labelledby="registryDropdown">
          <button id="delete_registry_button"
                  *ngIf='this.canDelete'
                  type="button"
                  class="dropdown-item"
                  (click)="delete()"
                  translate>Delete registry</button>
        </div>
      </div>

      <app-content-language [isSomeRegistryPage]="true"></app-content-language>
    </div>
  </div>

  <div class="row metabar">
    <div class="col-12">
      <div class="metaitem" *ngIf="codeRegistry.organizations.length > 0">
        <span translate>Organization</span><span>: </span>
        <span *ngFor="let organization of codeRegistry.mainOrganizations; let last = last">{{organization.prefLabel | translateValue:true}}{{last ? '' : ' · '}}</span>
      </div>
    </div>
  </div>

  <div class="row content-description-container" *ngIf="codeRegistry.description | translateValue as descriptionText">
    <div class="col-12">
      <app-expandable-text [text]="descriptionText"></app-expandable-text>
    </div>
  </div>

  <ul ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
    <li ngbNavItem="registry_codeschemes_tabs" id="registry_codeschemes_tabs">
      <a ngbNavLink>
        <span translate>CODE LISTS</span>
      </a>
      <ng-template ngbNavContent>
        <app-code-registry-code-schemes [codeRegistry]="codeRegistry" [codeSchemes]="codeSchemes"></app-code-registry-code-schemes>
      </ng-template>
    </li>
    <li ngbNavItem="registry_info_tab" id="registry_info_tab">
      <a ngbNavLink>
        <span translate>INFORMATION</span>
      </a>
      <ng-template ngbNavContent>
        <app-code-registry-information [codeRegistry]="codeRegistry"></app-code-registry-information>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
