<div class="row" *ngIf="codes.length > 0">
  <div class="col-md-4">
    <div class="input-group input-group-lg input-group-search">
      <input class="form-control"
             id="search_code_box_input"
             type="text"
             [(ngModel)]="searchTerm"
             placeholder="{{'Search for code' | translate}}"/>
    </div>
  </div>
</div>

<div class="row mb-4" *ngIf="codes.length > 0">
  <div class="col-12 search-text">
    <span *ngIf="emptySearch" translate>Search did not find any codes.</span>
    <span *ngIf="numberOfCodes == 1"><b>1</b> {{'code' | translate}}</span>
    <span *ngIf="numberOfCodes > 1"><b>{{numberOfCodes}}</b> {{'codes' | translate}}</span>
  </div>
</div>

<div class="codes row">
  <div class="col-md-6">
    <div *ngIf="allowExpandAllAndCollapseAll()" class="d-inline-block mb-1 float-left">
      <button id="expand_all_button"
              *ngIf="showExpandAll()"
              type="button"
              class="btn btn-action ml-3 float-left"
              (click)="expandAll()"
              translate>Expand all</button>
      <button id="collapse_all_button"
              *ngIf="showCollapseAll()"
              type="button"
              class="btn btn-action ml-3 float-left"
              (click)="collapseAll()"
              translate>Collapse all</button>
    </div>
  </div>

  <div id="codelist_code_list_div" class="col-md-12">
    <ul *ngIf="listedCodes.length <= 500" id="codelist_code_list_regular">
      <li *ngFor="let code of listedCodes; trackBy: codeIdentity">
        <app-hierarchy-code id="{{getIdIdentifier(code) + '_codelist_code_listitem'}}" [codes]="codes" [code]="code" [codeScheme]="codeScheme" [ignoreHierarchy]="searchTermHasValue()"></app-hierarchy-code>
      </li>
    </ul>
    <ul *ngIf="listedCodes.length > 500" class="hugelist" id="codelist_code_list_huge">
      <cdk-virtual-scroll-viewport style="height: 1000px" itemSize="20">
        <ng-container *cdkVirtualFor="let code of listedCodes">
          <li>
            <app-hierarchy-code id="{{getIdIdentifier(code) + '_codelist_code_listitem'}}" [codes]="codes" [code]="code" [codeScheme]="codeScheme" [ignoreHierarchy]="searchTermHasValue()"></app-hierarchy-code>
          </li>
        </ng-container>
      </cdk-virtual-scroll-viewport>

    </ul>
  </div>

  <div class="emptyCodes" *ngIf="codes.length === 0">
    <span translate>This code list does not have any codes yet.</span>
  </div>
</div>
