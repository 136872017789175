<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading" class="content-box">

  <div class="main">
    <div>
      <h1 class="text-content-wrap">{{code.getLongDisplayName(languageService, false)}}</h1>
    </div>

    <div>
      <div class="float-right">
        <div *ngIf="showMenu" ngbDropdown class="d-inline-block float-right ml-3" placement="bottom-right">
          <button class="btn btn-action" id="codeDropdown" ngbDropdownToggle translate>Choose action</button>
          <div ngbDropdownMenu aria-labelledby="codeDropdown">
            <button id="code_info_delete_button"
                    *ngIf="this.canDelete"
                    type="button"
                    class="dropdown-item"
                    (click)="delete()"
                    translate>Delete code</button>
          </div>
        </div>

        <app-content-language [languageCodes]="codeScheme.languageCodes"></app-content-language>
      </div>
    </div>
  </div>

  <div class="row metabar">
    <div class="col-12">
      <div class="metastatus">
        <app-status [status]="code.status"></app-status>
      </div>
      <div class="metaitem">
        <span translate>Registry</span><span>: </span><span class="routedLink"
                                                            (click)="navigateToRoute(codeScheme.codeRegistry.route)">{{codeScheme.codeRegistry.getDisplayName(languageService)}}</span>
      </div>
      <div class="metaitem">
        <span translate>Code list</span><span>: </span>
        <span class="routedLink"
              (click)="navigateToRoute(codeScheme.route)">{{codeScheme.getDisplayName(languageService)}}</span>
      </div>
      <div class="metaitem" *ngIf="codeScheme.infoDomains.length > 0">
        <span translate>InfoDomain</span><span>: </span>
        <span class="badge badge-light"
              *ngFor="let infoDomain of codeScheme.infoDomains">{{infoDomain.prefLabel | translateValue:true}}</span>
      </div>
      <div class="metaitem" *ngIf="codeScheme.organizations.length > 0">
        <span translate>Organization</span><span>: </span>
        <span
          *ngFor="let organization of codeScheme.mainOrganizations; let last = last">{{organization.prefLabel | translateValue:true}}{{last ? '' : ' · '}}</span>
      </div>
    </div>
  </div>

  <div class="row content-description-container" *ngIf="code.description | translateValue as descriptionText">
    <div class="col-12">
      <app-expandable-text [text]="descriptionText"></app-expandable-text>
    </div>
  </div>

  <ul ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
    <li ngbNavItem="code_info_tab" id="code_info_tab">
      <a ngbNavLink>
        <span translate>INFORMATION</span>
      </a>
      <ng-template ngbNavContent>
        <app-code-information [code]="code"
                              [codeScheme]="codeScheme">
        </app-code-information>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
