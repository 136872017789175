<table *ngIf="codeScheme.allVersions.length > 0" class="table table-sm">
  <thead class="thead-light">
  <tr>
    <th scope="col" translate width="30%">Validity</th>
    <th scope="col" translate width="30%">Name</th>
    <th scope="col" translate width="30%">Status</th>
    <th scope="col" width="10%">&nbsp;</th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let version of codeScheme.allVersions">
    <td scope="row" width="30%">{{version.validityDateRange}}</td>
    <td width="30%"><a href="{{getVersionUri(version.uri)}}" target="_blank" rel="noopener noreferrer">{{ getVersionDisplayName(version) }}</a></td>
    <td width="30%"><app-status class="float-left" [status]="version.status"></app-status></td>
    <td width="10%">&nbsp;</td>
  </tr>
  </tbody>
</table>
