<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading" class="content-box">
  <div class="main">
    <div>
      <h1 class="float-left">{{this.title | translate}}</h1>
    </div>
    <div class="float-right">
      <app-content-language [languageCodes]="codeScheme.languageCodes"></app-content-language>
    </div>
  </div>

  <form [formGroup]="extensionForm" #form="ngForm">

    <div class="row">
      <div class="col-12">
        <app-editable-buttons [form]="form"></app-editable-buttons>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-4 col-md-5">
        <app-literal-input [id]="'extension_codevalue_input'"
                           [label]="'LABEL_TEXT_CREATE_EXTENSION_ID' | translate"
                           [restrict]="isCodeExtension"
                           [required]="true"
                           [infoText]="'INFO_TEXT_CREATE_EXTENSION_ID'"
                           [formControlName]="'codeValue'"></app-literal-input>

        <div class="form-group">
          <app-error-messages id="extension_form_error_messages" [control]="extensionForm"></app-error-messages>
        </div>

        <app-localizable-input-with-language-indicator-for-all-languages *ngIf="!isCodeExtension"
                                                                         [id]="'extension_name_input'"
                                                                         [htmlIdentifierPrefix]="'extension_name_input'"
                                                                         [label]="'Extension name' | translate"
                                                                         [infoText]="'INFO_TEXT_EXTENSION_NAME'"
                                                                         [parentElementsLanguageCodes]="codeScheme.languageCodes"
                                                                         [formControlName]="'prefLabel'"></app-localizable-input-with-language-indicator-for-all-languages>

        <app-literal [label]="'Extension type' | translate"
                     [value]="propertyType.prefLabel | translateValue:true"
                     [infoText]="'INFO_TEXT_EXTENSION_TYPE'"></app-literal>


      </div>

      <div class="col-lg-4 col-md-5">
        <app-code-scheme-input *ngIf="allowCodeSchemes"
                               id="extension_code_scheme_input"
                               [infoText]="'INFO_TEXT_EXTENSION_CODE_LISTS'"
                               [label]="'Code list' | translate"
                               [parentCodeScheme]="codeScheme"
                               [formControlName]="'codeSchemes'"></app-code-scheme-input>

        <div class="row">
          <div class="col-md-4" style="line-height: 45px; white-space: nowrap">
            <div class="checkbox">
              <input id="auto_create_members_checkbox" type="checkbox" (click)="toggleAutoCreateMembers()">
              <label style="vertical-align: 5%; padding-left: 5px;" for="auto_create_members_checkbox" translate>Automatically create a member for each code</label>
            </div>
          </div>
        </div>

        <app-status-input id="extension_status_input"
                          [infoText]="'INFO_TEXT_EXTENSION_STATUS'"
                          [formControlName]="'status'"></app-status-input>

        <app-date-range-input id="extension_validity_input"
                              [label]="'Validity' | translate"
                              [infoText]="'INFO_TEXT_EXTENSION_VALIDITY'"
                              [formControlName]="'validity'"></app-date-range-input>
      </div>
    </div>

  </form>
</div>
