<nav class="navbar navbar-expand-md navbar-light">

  <div class="navbar-header">
    <a id="main_page_link" class="navbar-brand" href="/">
      <!--<app-logo></app-logo>-->
      <span translate>Reference Data</span>
      <span *ngIf="environmentIdentifier">{{environmentIdentifier}}</span>
    </a>
  </div>

  <ul class="navbar-nav ml-auto">

    <li *ngIf="fakeableUsers.length > 0" class="nav-item dropdown" ngbDropdown>
      <a id="impersonate_user_link" class="nav-link" ngbDropdownToggle translate>Impersonate user</a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
        <a id={{user.email}} class="dropdown-item" *ngFor="let user of fakeableUsers" (click)="fakeUser(user.email)">
          {{user.firstName}} {{user.lastName}}
        </a>
      </div>
    </li>
    <li class="nav-item" *ngIf="!isLoggedIn()">
      <a id="log_in_link" class="nav-link" (click)="logIn()" translate>LOG IN</a>
    </li>
    <li class="nav-item logged-in" *ngIf="isLoggedIn()">
      <span>{{user.name}}</span>
      <a id="log_out_link" class="nav-link" (click)="logOut()" translate>LOG OUT</a>
    </li>
    <li class="nav-item dropdown" placement="bottom-right" ngbDropdown>
      <a id="select_lang_dropdown" class="dropdown-toggle nav-link btn btn-language" ngbDropdownToggle>{{language.toUpperCase()}}</a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
        <a *ngFor="let availableLanguage of availableLanguages"
           class="dropdown-item"
           [id]="availableLanguage.code"
           [class.active]="isLanguageSelected(availableLanguage.code)"
           (click)="language = availableLanguage.code">
          <span>{{availableLanguage.name}}</span>
        </a>
      </div>
    </li>
    <li class="nav-item dropdown" placement="bottom-right" ngbDropdown>
      <a id="navigation_menu_dropdown" class="nav-link btn-menu" ngbDropdownToggle>
        <app-menu></app-menu>
      </a>
      <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
        <a id="navigation_log_out_link" class="dropdown-item" *ngIf="isLoggedIn()" (click)="logOut()">
          <i class="fas fa-sign-out-alt"></i>
          <span translate>LOG OUT</span>
        </a>
        <a id="navigation_log_in_link" class="dropdown-item" *ngIf="!isLoggedIn()" (click)="logIn()">
          <i class="fas fa-sign-in-alt"></i>
          <span translate>LOG IN</span>
        </a>
        <div class="dropdown-divider" [hidden]="noMenuItemsAvailable"></div>
        <a class="dropdown-item"
           id="navigation_link_user_details"
           *ngIf="isLoggedIn()"
           [routerLink]="['/userDetails']" translate>User details</a>
        <a class="dropdown-item"
           id="navigation_link_registries"
           [routerLink]="['/registries']" translate>Registries</a>
        <a id="navigation_interoperability_platform_link"
           class="dropdown-item"
           href="https://yhteentoimiva.suomi.fi/" target="_blank" rel="noopener noreferrer">yhteentoimiva.suomi.fi</a>
        <a id="navigation_terminologies_link"
           class="dropdown-item"
           [href]="configurationService.terminologyUrl" target="_blank" rel="noopener noreferrer" translate>Suomi.fi Terminologies</a>
        <a id="navigation_data_vocabularies_link"
           class="dropdown-item"
           [href]="configurationService.dataModelUrl" target="_blank" rel="noopener noreferrer" translate>Suomi.fi Data Vocabularies</a>
        <a id="navigation_comments_link"
           class="dropdown-item"
          [href]="configurationService.commentsUrl" target="_blank" rel="noopener noreferrer" translate>Suomi.fi Comments</a>
        <a class="dropdown-item"
           id="navigation_groupmanagement_link"
           *ngIf="showGroupManagementUrl()"
           [href]="configurationService.groupManagementUrl" target="_blank" rel="noopener noreferrer" translate>User right management</a>
      </div>
    </li>
  </ul>

</nav>
