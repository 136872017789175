<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading" class="content-box">
  <div class="main">
    <div>
      <h1 class="text-content-wrap" translate>Registries</h1>
    </div>

    <div class="float-right">
      <div *ngIf="canAddRegistry()" ngbDropdown class="d-inline-block float-right ml-3" placement="bottom-right">
        <button class="btn btn-action" id="registryDropdown" ngbDropdownToggle translate>Choose action</button>
        <div ngbDropdownMenu aria-labelledby="registryDropdown">
          <button *ngIf="canAddRegistry()" id="create_registry_button"
                  type="button"
                  class="dropdown-item"
                  (click)="createRegistry()"
                  translate>Create registry</button>
        </div>
      </div>

      <app-content-language [isSomeRegistryPage]="true"></app-content-language>
    </div>
  </div>

  <div class="codeRegistries row">
    <div id="code_registry_div" class="col-md-12">
      <ul id="code_registry_regular">
        <li *ngFor="let codeRegistry of codeRegistries; trackBy: codeRegistryIdentity">
          <app-code-registry-listitem id="{{codeRegistry.idIdentifier + '_registry_listitem'}}"
                                      [codeRegistry]="codeRegistry"></app-code-registry-listitem>
        </li>
      </ul>
    </div>

    <div class="emptyRegistries" *ngIf="codeRegistries.length === 0">
      <span translate>No registries found.</span>
    </div>
  </div>
</div>
