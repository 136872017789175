<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading" class="content-box">
  <div class="main">
    <div>
      <h1 class="text-content-wrap">{{codeScheme.getDisplayName(languageService, false)}}</h1>
    </div>

    <div class="float-right">
      <div *ngIf="showMenu" ngbDropdown class="d-inline-block float-right ml-3" placement="bottom-right">
        <button class="btn btn-action" id="codeSchemeDropdown" ngbDropdownToggle translate>Choose action</button>
        <div ngbDropdownMenu aria-labelledby="codeSchemeDropdown">
          <button id="add_subscription_button"
                  *ngIf="canAddSubscription"
                  type="button"
                  class="dropdown-item"
                  (click)="addSubscription()"
                  translate>Add email subscription</button>
          <button id="delete_subscription_button"
                  *ngIf="canRemoveSubscription"
                  type="button"
                  class="dropdown-item"
                  (click)="removeSubscription()"
                  translate>Remove email subscription</button>
          <button id="create_code_button"
                  *ngIf="canAddCode"
                  type="button"
                  class="dropdown-item"
                  (click)="createCode()"
                  translate>Create code</button>
          <button id="import_codes_button"
                  *ngIf="canAddCode"
                  type="button"
                  class="dropdown-item"
                  (click)="importCodes()"
                  translate>Import codes</button>
          <button id="clone_codelist_button"
                  *ngIf="canCreateANewVersionFromCodeScheme"
                  type="button"
                  class="dropdown-item"
                  (click)="createANewVersionFromThisCodeScheme()"
                  translate>Create a new version</button>
          <button id="clone_codelist_from_file_button"
                  *ngIf="canCreateANewVersionFromCodeScheme"
                  type="button"
                  class="dropdown-item"
                  (click)="createANewVersionOfThisCodeSchemeFromFile()"
                  translate>Create a new version from file</button>
          <button id="update_codelist_from_file_button"
                  *ngIf="canUpdateCodeSchemeFromFile"
                  type="button"
                  class="dropdown-item"
                  (click)="updateCodeSchemeFromFile()"
                  translate>Update code list</button>
          <button id="mass_migrate_codelist_codes_statuses_button"
                  *ngIf="canMassMigrateCodeListsCodeStatuses"
                  type="button"
                  class="dropdown-item"
                  (click)="massMigrateCodeListsCodesStatuses()"
                  translate>Update code list's codes' statuses</button>
          <button id="attach_variant_button"
                  *ngIf="canAttachOrDetachAVariant"
                  type="button"
                  class="dropdown-item"
                  (click)="openVariantSearchModal()"
                  translate>Attach variant to code list</button>
          <button id="create_extension_calculationhierarchy_button"
                  *ngIf="canAddExtension"
                  type="button"
                  class="dropdown-item"
                  (click)="createExtension('calculationHierarchy')"
                  translate>Create calculation hierarchy</button>
          <button id="create_extension_definitionhierarchy_button"
                  *ngIf="canAddExtension"
                  type="button"
                  class="dropdown-item"
                  (click)="createExtension('definitionHierarchy')"
                  translate>Create definition hierarchy</button>
          <button id="create_extension_cross-reference-list_button"
                  *ngIf="canAddExtension"
                  type="button"
                  class="dropdown-item"
                  (click)="createExtension('crossReferenceList')"
                  translate>Create cross-reference list</button>
          <button id="create_extension_dpmmetric_button"
                  *ngIf="canAddExtensionWithType('dpmMetric')"
                  type="button"
                  class="dropdown-item"
                  (click)="createExtension('dpmMetric')"
                  translate>Create DPM metric</button>
          <button id="create_extension_dpmexplicitdomain_button"
                  *ngIf="canAddExtensionWithType('dpmExplicitDomain')"
                  type="button"
                  class="dropdown-item"
                  (click)="createExtension('dpmExplicitDomain')"
                  translate>Create DPM explicit domain</button>
          <button id="create_extension_dpmdimension_button"
                  *ngIf="canAddExtensionWithType('dpmDimension')"
                  type="button"
                  class="dropdown-item"
                  (click)="createExtension('dpmDimension')"
                  translate>Create DPM dimension</button>
          <button id="create_extension_dpmdtypeddomain_button"
                  *ngIf="canAddExtensionWithType('dpmTypedDomain')"
                  type="button"
                  class="dropdown-item"
                  (click)="createExtension('dpmTypedDomain')"
                  translate>Create DPM typed domain</button>
          <button id="import_extensions_button"
                  *ngIf="canAddExtension"
                  type="button"
                  class="dropdown-item"
                  (click)="importExtensions()"
                  translate>Import extensions</button>
          <button id="delete_codelist_button"
                  *ngIf="canDeleteCodeScheme"
                  type="button"
                  class="dropdown-item"
                  (click)="delete()"
                  translate>{{deleteCodeSchemeButtonTitle}}</button>
        </div>
      </div>

      <div ngbDropdown class="d-inline-block float-right ml-3" placement="bottom-right">
        <button class="btn btn-action" id="exportDropdown" ngbDropdownToggle translate>Export</button>
        <div ngbDropdownMenu aria-labelledby="exportDropdown">
          <a id="codelist_export_excel_link" href='{{codeScheme.url}}/?format=excel' target="_blank" rel="noopener noreferrer"
             class="dropdown-item" translate>Export codelist Excel</a>
          <a id="codelist_export_csv_link" href='{{codeScheme.url}}/?format=csv' target="_blank" rel="noopener noreferrer"
             class="dropdown-item" translate>Export codelist CSV</a>
          <a id="codelist_export_codes_csv_link" href='{{codeScheme.url}}/codes/?format=csv' target="_blank" rel="noopener noreferrer"
             class="dropdown-item" translate>Export codes CSV</a>
          <a id="codelist_export_codes_excel_link" href='{{codeScheme.url}}/codes/?format=excel' target="_blank" rel="noopener noreferrer"
             class="dropdown-item" translate>Export codes Excel</a>
          <a id="codelist_export_json_inline_link"
             href='{{codeScheme.url}}/?format=json&embedCodes=true&embedExtensions=true&embedMembers=true&expand=extension,member,codeScheme,code,memberValue,codeRegistry,organization,valueType,externalReference,propertyType&downloadFile=true&pretty'
             target="_blank"
             rel="noopener noreferrer"
             class="dropdown-item" translate>Export codelist JSON</a>
          <a id="codelist_export_json_as_file_link"
             href='{{codeScheme.url}}/?format=json&embedCodes=true&embedExtensions=true&embedMembers=true&expand=extension,member,codeScheme,code,memberValue,codeRegistry,organization,valueType,externalReference,propertyType&downloadFile=false&pretty'
             target="_blank"
             rel="noopener noreferrer"
             class="dropdown-item" translate>Show codelist JSON</a>
          <a id="codelist_export_codes_json_array_link" href='{{codeScheme.url}}/codes/?array' target="_blank" rel="noopener noreferrer"
             class="dropdown-item" translate>Show JSON enumeration</a>
          <a id="codelist_export_codes_json_array_download_link" href='{{codeScheme.url}}/codes/?array&downloadArray=true' target="_blank" rel="noopener noreferrer"
             class="dropdown-item" translate>Download JSON enumeration</a>
        </div>
      </div>

      <app-content-language [languageCodes]="contentLanguages"></app-content-language>

      <div *ngIf="hasSubscription" class="subscription">
        <span class="subscription-icon icon-bell"
              ngbTooltip="{{ 'You will receive an email notification when this resource changes.' | translate }}"></span>
      </div>
    </div>
  </div>

  <div class="row metabar">
    <div class="col-12">
      <div class="metastatus">
        <app-status [status]="codeScheme.status"></app-status>
      </div>
      <div class="metaitem">
        <span translate>Registry</span><span>: </span>
        <span class="routedLink"
              (click)="navigateToRoute(codeScheme.codeRegistry.route)">{{codeScheme.codeRegistry.getDisplayName(languageService)}}</span>
      </div>
      <div class="metaitem" *ngIf="codeScheme.infoDomains.length > 0">
        <span translate>InfoDomain</span><span>: </span>
        <span class="badge badge-light"
              *ngFor="let infoDomain of codeScheme.infoDomains">{{infoDomain.prefLabel | translateValue:true}}</span>
      </div>
      <div class="metaitem" *ngIf="codeScheme.organizations.length > 0">
        <span translate>Organization</span><span>: </span>
        <span
          *ngFor="let organization of codeScheme.mainOrganizations; let last = last">{{organization.prefLabel | translateValue:true}}{{last ? '' : ' · '}}</span>
      </div>
    </div>
  </div>

  <div class="row content-description-container" *ngIf="codeScheme.description | translateValue as descriptionText">
    <div class="col-12">
      <app-expandable-text [text]="descriptionText"></app-expandable-text>
    </div>
  </div>

  <ul ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)" [activeId]="initialTabId">
    <li ngbNavItem="codelist_codes_tab"
        id="codelist_codes_tab"
        *ngIf="codes.length > 0">
      <a ngbNavLink>
        <span translate>CODES</span>
      </a>
      <ng-template ngbNavContent>
        <app-code-scheme-codes [codes]="codes" [codeScheme]="codeScheme"
                               [prefilledSearchTermForCode]="prefilledSearchTermForCode"></app-code-scheme-codes>
      </ng-template>
    </li>

    <li ngbNavItem="codelist_info_tab" id="codelist_info_tab">
      <a ngbNavLink>
        <span translate>INFORMATION</span>
      </a>
      <ng-template ngbNavContent>
        <app-code-scheme-information [codeScheme]="codeScheme"
                                     [codesOfTheCodeScheme]="codes"
                                     [languageCodes]="codeScheme.languageCodes"
                                     (changeLanguage)="changeLanguages($event)"
                                     (changeCodeStatusesAsWell)="toggleChangeCodeStatusesAsWellWhenSavingCodeScheme($event)"></app-code-scheme-information>
      </ng-template>
    </li>

    <li *ngIf="extensions.length > 0"
        ngbNavItem="codelist_extensions_tab"
        id="codelist_extensions_tab">
      <a ngbNavLink>
        <span translate>EXTENSIONS</span>
      </a>
      <ng-template ngbNavContent>
        <app-code-scheme-extensions [codeScheme]="codeScheme"
                                    [extensions]="extensions"></app-code-scheme-extensions>
      </ng-template>
    </li>

    <li *ngIf="codeScheme.allVersions.length > 0"
        ngbNavItem="codelist_versionhistory_tab"
        id="codelist_versionhistory_tab">
      <a ngbNavLink>
        <span translate>versionHistory</span>
      </a>
      <ng-template ngbNavContent>
        <app-code-scheme-versions [codeScheme]="codeScheme"></app-code-scheme-versions>
      </ng-template>
    </li>

    <li
      *ngIf="codeScheme.variantsOfThisCodeScheme.length > 0 || codeScheme.variantMothersOfThisCodeScheme.length > 0"
      ngbNavItem="codelist_variants_tab"
      id="codelist_variants_tab">
      <a ngbNavLink>
        <span translate>variants</span>
      </a>
      <ng-template ngbNavContent>
        <dt><label *ngIf="codeScheme.variantsOfThisCodeScheme.length > 0" translate>Variants of this code list</label></dt>
        <app-code-scheme-variants (detachVariantRequest)="reloadCodeScheme()"
                                  [codeScheme]="codeScheme"></app-code-scheme-variants>
        <dt><label *ngIf="codeScheme.variantMothersOfThisCodeScheme.length > 0" translate>Variant in other code lists</label>
        </dt>
        <app-code-scheme-variant-mothers [codeScheme]="codeScheme"></app-code-scheme-variant-mothers>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
