<div class="content-box">
  <h1 translate>Add code list</h1>
  <p>
    <span translate>Import code list by uploading a CSV- or Excel formatted file. Look up more detailed guide and information about using code list files from</span>
    <a id="user_support_link" href="https://vrk-ewiki.eden.csc.fi/pages/viewpage.action?pageId=27267897" target="_blank" rel="noopener noreferrer" class="user-support-link" translate>LINK_TXT_FROM_SERVICE_HELP</a>.
  </p>
  <p translate>Using the imported file, a new code list is created or an older code list is updated with new meta-information. Upon uploading the file, you get to review and cancel the changes if needed.</p>
  <button id="import_from_file_button" type="button" class="btn btn-action" (click)="importFromFile()" translate>Import code list from a file</button>
  <br/><br/>
  <p translate>You can also add a new code list by creating it via the user interface of the Koodistot service.</p>
  <button id="create_new_codelist_button" type="button" class="btn btn-action" (click)="createNewCodeScheme()" translate>Create new code list</button>
  <br/><br/>
</div>
