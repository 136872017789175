<div class="extensions row">
  <div id="extension_members_div" class="col-md-12">
    <table class="table" *ngIf="listedMembers.length <= 500" id="extension_members_regular">
      <tr *ngFor="let member of listedMembers; trackBy: memberIdentity">
        <td [id]="member.id + '_view_member'" class="" style="white-space: nowrap;  font-size:large">
          <a href="{{configurationService.getUriWithEnv(member.code.uri)}}" target="_blank" rel="noopener noreferrer">
            {{member.getDisplayName(languageService, translateService) }}
          </a>
        </td>
        <td [id]="member.relatedMember.id + '_view_related_member'" class="" style="width: 99%; font-size:large">
          <a href="{{configurationService.getUriWithEnv(member.relatedMember.code.uri)}}" target="_blank" rel="noopener noreferrer">
            {{member.relatedMember.getDisplayName(languageService, translateService) }}
          </a>
        </td>
    </table>
    <table class="table" *ngIf="listedMembers.length > 500" class="hugelist" id="extension_members_huge">
      <tr>
        <cdk-virtual-scroll-viewport style="height: 1000px" itemSize="20">
          <ng-container *cdkVirtualFor="let member of listedMembers">
            <td [id]="member.id + '_view_member'" class="" style="white-space: nowrap;  font-size:large">
              <a href="{{configurationService.getUriWithEnv(member.code.uri)}}" target="_blank" rel="noopener noreferrer">
                {{member.getDisplayName(languageService, translateService) }}
              </a>
            </td>
            <td [id]="member.relatedMember.id + '_view_related_member'" class="" style="width: 99%; font-size:large">
              <a href="{{configurationService.getUriWithEnv(member.relatedMember.code.uri)}}" target="_blank" rel="noopener noreferrer">
                {{member.relatedMember.getDisplayName(languageService, translateService) }}
              </a>
            </td>
          </ng-container>
        </cdk-virtual-scroll-viewport>
      </tr>
    </table>
  </div>

  <div class="emptyMembers" *ngIf="parentMembers.length === 0">
    <span translate>This extension does not have any cross references yet.</span>
  </div>
</div>
