<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading" class="content-box">
  <div class="main">
    <div>
      <div class="d-inline-block">
        <h1 class="text-content-wrap">{{extension.getDisplayName(languageService)}}</h1>
      </div>
    </div>

    <div>
      <div *ngIf="showMenu" ngbDropdown class="d-inline-block float-right ml-3" placement="bottom-right">
        <button class="btn btn-action" id="extensionDropdown" ngbDropdownToggle translate>Choose action</button>
        <div ngbDropdownMenu aria-labelledby="extensionDropdown">
          <button id="create_member_button"
                  *ngIf="canAddMember"
                  type="button"
                  class="dropdown-item"
                  (click)="createMember()"
                  translate>Create member</button>
          <button id="create_missing_members_button"
                  *ngIf="canCreateMissingMembers"
                  type="button"
                  class="dropdown-item"
                  (click)="createMissingMembers()"
                  translate>Create missing members for the extension</button>
          <button id="import_members_button"
                  *ngIf="canImportMembers"
                  class="dropdown-item"
                  (click)="importMembers()"
                  translate>Import members</button>
          <button id="delete_extension_button"
                  *ngIf="canDelete"
                  type="button"
                  class="dropdown-item"
                  (click)="delete()"
                  translate>Delete extension</button>
        </div>
      </div>

      <div ngbDropdown class="d-inline-block float-right ml-3" placement="bottom-right">
        <button class="btn btn-action" id="exportDropdown" ngbDropdownToggle translate>Export</button>
        <div ngbDropdownMenu aria-labelledby="exportDropdown">
          <a id="codelist_export_excel_link" href="{{extension.url}}/members/?format=excel"
             target="_blank"
             rel="noopener noreferrer"
             class="dropdown-item" translate>Export members Excel</a>
          <a id="codelist_export_csv_link" href="{{extension.url}}/members/?format=csv"
             target="_blank"
             rel="noopener noreferrer"
             class="dropdown-item" translate>Export members CSV</a>
          <a id="codelist_export_cross_reference_excel_link" href="{{extension.url}}/?format=excel&crossreferencelist=true"
             *ngIf="showCrossReferenceExportFunctions"
             target="_blank"
             rel="noopener noreferrer"
             class="dropdown-item" translate>Export Cross-Reference List Excel</a>
          <a id="codelist_export_cross_reference_csv_link" href="{{extension.url}}/members/?format=csv&crossreferencelist=true"
             *ngIf="showCrossReferenceExportFunctions"
             target="_blank"
             rel="noopener noreferrer"
             class="dropdown-item" translate>Export Cross-Reference List CSV</a>
        </div>
      </div>

      <app-content-language [languageCodes]="codeScheme.languageCodes"></app-content-language>
    </div>
  </div>

  <div class="row metabar">
    <div class="col-12">
      <div class="metastatus">
        <app-status [status]="extension.status"></app-status>
      </div>
      <div class="metaitem">
        <span translate>Registry</span><span>: </span>
        <span class="routedLink"
              (click)="navigateToRoute(extension.parentCodeScheme.codeRegistry.route)">{{extension.parentCodeScheme.codeRegistry.getDisplayName(languageService)}}</span>
      </div>
      <div class="metaitem">
        <span translate>Code list</span><span>: </span>
        <span class="routedLink"
              (click)="navigateToRoute(extension.parentCodeScheme.route)">{{extension.parentCodeScheme.getDisplayName(languageService)}}</span>
      </div>
      <div class="metaitem" *ngIf="extension.parentCodeScheme.organizations.length > 0">
        <span translate>Organization</span><span>: </span>
        <span *ngFor="let organization of extension.parentCodeScheme.mainOrganizations; let last = last">{{organization.prefLabel | translateValue:true}}{{last ? '' : ' · '}}</span>
      </div>
    </div>
  </div>

  <ul ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)" [activeId]="initialTabId">
    <li *ngIf="showCrossReferenceListTab"
        ngbNavItem="extension_crossreferencelist_tab"
        id="extension_crossreferencelist_tab">
      <a ngbNavLink>
        <span translate>CROSS-REFERENCE LIST</span>
      </a>
      <ng-template ngbNavContent>
        <app-extension-crossreferencelist [members]="members"
                               [extension]="extension"></app-extension-crossreferencelist>
      </ng-template>
    </li>
    <li *ngIf="showMembersTab"
        ngbNavItem="extension_members_tab"
        id="extension_members_tab">
      <a ngbNavLink>
        <span translate>MEMBERS</span>
      </a>
      <ng-template ngbNavContent *ngIf="!loading">
        <app-extension-members [members]="members"
                               [extension]="extension"
                               [nrOfCreatedMissingMembers]="numberOfMissingMembersThatGotCreated"></app-extension-members>
      </ng-template>
    </li>
    <li ngbNavItem="extension_info_tab" id="extension_info_tab">
      <a ngbNavLink>
        <span translate>INFORMATION</span>
      </a>
      <ng-template ngbNavContent>
        <app-extension-information [extension]="extension"></app-extension-information>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
