<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div class="yti-tool-front-page" *ngIf="!loading">
  <div class="row mb-12">
    <div class="col-md-10">
      <p translate>Frontpage long description</p>
    </div>
    <div *ngIf="canCreateCodeScheme()" class="col-md-2 float-right d-inline-block">
      <button id="import_codelist_button"
              type="button"
              class="btn btn-action float-right"
              (click)="importCodeScheme()"
              translate>Add code list</button>
    </div>
  </div>

  <div><span class="search-label" translate>Search for code lists</span>
    <app-information-symbol [infoText]="'INFO_TEXT_FRONTPAGE_SEARCH'"></app-information-symbol>
  </div>
  <div class="row">
    <div class="col-md-6 mb-3">
      <div class="input-group input-group-lg input-group-search">
        <input class="form-control"
               #schemeSearch
               id="search_box_input"
               type="text"
               [ngClass]="{'is-invalid': searchError}"
               [(ngModel)]="searchTerm"
               placeholder="{{'Search term' | translate}}"/>
      </div>
    </div>
    <div class="col-md-6" style="line-height: 45px">
      <div class="checkbox">
        <span style="padding-right: 10px; vertical-align: top;"translate>Extend search to</span>
        <input id="search_code_details_checkbox" type="checkbox" (click)="toggleSearchCodes()">
        <label for="search_code_details_checkbox" translate>to_codes</label>
        &nbsp;
        <input id="search_extension_details_checkbox" type="checkbox" (click)="toggleSearchExtensions()">
        <label for="search_extension_details_checkbox" translate>to_extensions</label>
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md-4">
      <div class="inline-label">
        <span class="search-label" translate>Filter with information domain</span>
        <app-information-symbol [infoText]="'INFO_TEXT_FRONTPAGE_FILTER_W_INFODOMAIN'"></app-information-symbol>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="information-domain-container">
        <div class="content-box">
          <div class="information-domain"
               *ngFor="let infoDomain of infoDomainsWithAtLeastOneEntry"
               [class.active]="isInfoDomainSelected(infoDomain.entity)"
               [id]="infoDomain.entity.codeValue + '_infodomain_filter_link'"
               (click)="toggleInfoDomain(infoDomain.entity)">
            <img [src]="groupIconSrc(infoDomain.entity.codeValue)">
            <span class="name" style="vertical-align: middle;">{{infoDomain.entity.prefLabel | translateValue:true }}</span>
            <span class="count">({{infoDomain.count}})</span>
          </div>
        </div>
      </div>
    </div>

    <div class="col-md-8">

      <div *ngIf="searchInProgress" class="content-box">
        <app-ajax-loading-indicator></app-ajax-loading-indicator>
      </div>

      <div class="content-box result-list-container" *ngIf="!searchInProgress">
        <div class="row mb-4">
          <div class="col-md-12 result-list-filter-row">
            <span class="search-label search-label-inline with-info" style="padding-right: 0px"
                  translate>Filter results</span>
            <app-information-symbol [infoText]="'INFO_TEXT_FRONTPAGE_FILTER_RESULTS'"></app-information-symbol>

            <div class="result-list-filter-dropdowns">
              <app-filter-dropdown id="organization_filter_dropdown"
                                   [filterSubject]="organization$"
                                   [options]="organizationOptions"></app-filter-dropdown>

              <app-filter-dropdown id="registry_filter_dropdown"
                                   [filterSubject]="registry$"
                                   [options]="registryOptions"></app-filter-dropdown>

              <app-filter-dropdown id="status_filter_dropdown"
                                   [filterSubject]="status$"
                                   [options]="statusOptions"></app-filter-dropdown>

              <app-filter-dropdown id="propertytype_filter_dropdown"
                                   [filterSubject]="extensionPropetyType$"
                                   [options]="extensionPropertyTypeOptions"></app-filter-dropdown>
            </div>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-12">
            <span *ngIf="filteredCodeSchemes.length == 0" translate>Search did not find any code lists.</span>
            <span *ngIf="filteredCodeSchemes.length == 1">1 {{'search result' | translate}}</span>
            <span *ngIf="filteredCodeSchemes.length > 1">{{filteredCodeSchemes.length}} {{'search results' | translate}}</span>
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="result-list-item" *ngFor="let codeScheme of filteredCodeSchemes">
              <span class="type"><i class="material-icons icon-orange">account_balance</i>{{codeScheme.codeRegistry.getDisplayName(languageService, true)}}</span>

              <app-status class="status" [status]="codeScheme.status"></app-status>

              <a class="name" [routerLink]="codeScheme.route">{{codeScheme.getDisplayName(languageService, true)}}</a>

              <div class="meta-information-row">
                <ul class="organizations dot-separated-list">
                  <li class="organization" *ngFor="let org of codeScheme.mainOrganizations">
                    {{org.prefLabel | translateValue:true}}
                  </li>
                </ul>
                <span class="information-domains">
                      <span class="badge badge-light" *ngFor="let infoDomain of codeScheme.getDisplayInfoDomainListing(languageService, true)">
                        {{infoDomain}}
                      </span>
                    </span>
              </div>

              <div *ngIf="codeScheme.description | translateValue: true as descriptionText" class="description-component-container">
                <app-expandable-text [text]="descriptionText"></app-expandable-text>
              </div>

              <div class="deep-results" *ngIf="codeScheme.searchHitsOfTheTypeCode.length > 0 || codeScheme.searchHitsOfTheTypeExtension.length > 0">
                <div class="deep-results-title" translate>Searchhits</div>

                <div class="deep-results-section" *ngIf="codeScheme.searchHitsOfTheTypeCode.length > 0 || codeScheme.searchHitsOfTheTypeExtension.length > 0">
                  <app-searchhits-list [totalNrOfSearchHitsCodes]="codeScheme.totalNrOfSearchHitsCodes"
                                       [totalNrOfSearchHitsExtensions]="codeScheme.totalNrOfSearchHitsExtensions"
                                       [codeScheme]="codeScheme"
                                       [searchTerm]="searchTerm"
                                       [searchHitsCodesAll]="codeScheme.searchHitsOfTheTypeCode"
                                       [searchHitsExtensionsAll]="codeScheme.searchHitsOfTheTypeExtension"></app-searchhits-list>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>
