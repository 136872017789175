<div class="extensions row">
  <ul ngbNav #secondaryNav="ngbNav" class="nav-tabs col-md-12 exttabs">
    <li *ngFor="let propertyExtensions of extensionsByType"
        [ngbNavItem]="'codelist_extension_' + propertyExtensions.localNameForId + '_tab'"
        [id]="'codelist_extension_' + propertyExtensions.localNameForId + '_tab'">
      <a ngbNavLink>
        <span>{{propertyExtensions.label}}</span>
      </a>
      <ng-template ngbNavContent>
        <div id="codelist_extension_list_div" class="col-md-12">
          <ul id="codelist_extension_regular">
            <li *ngFor="let extension of propertyExtensions.extensions">
              <div id="{{extension.codeValue}}_codelist_extension_listitem">
                <app-extension-listitem [extension]="extension"></app-extension-listitem>
              </div>
            </li>
          </ul>
        </div>
      </ng-template>
    </li>

    <li *ngIf="hasCodeExtensions"
        ngbNavItem="codelist_codeextensions_tab"
        id="codelist_codeextensions_tab">
      <a ngbNavLink>
        <span translate>CODE EXTENSIONS</span>
      </a>
      <ng-template ngbNavContent>
        <div id="codelist_codeextension_list_div" class="col-md-12">
          <ul id="codelist_codeextension_regular">
            <li *ngFor="let extension of codeExtensions">
              <div id="{{extension.codeValue}}_codelist_codeextension_listitem">
                <app-extension-listitem [extension]="extension"></app-extension-listitem>
              </div>
            </li>
          </ul>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="secondaryNav"></div>
</div>
