<form [formGroup]="codeForm" #form="ngForm">

  <div class="row">
    <div class="col-5">
      <app-literal [label]="'Code list' | translate"
                   [value]="code.codeScheme.getDisplayName(languageService)"
                   [infoText]="'INFO_TEXT_CODE_CODELIST_NAME'"></app-literal>

      <app-literal [label]="'LABEL_TEXT_CODE_VALUE' | translate"
                   [value]="code.codeValue"
                   [infoText]="'INFO_TEXT_CODE_VALUE'"></app-literal>

      <app-localizable-input-with-language-indicator-for-all-languages [id]="'code_name_input'"
                                                                       [htmlIdentifierPrefix]="'code_name_input'"
                                                                       [label]="'Code name' | translate"
                                                                       [infoText]="'INFO_TEXT_CODE_NAME'"
                                                                       [parentElementsLanguageCodes]="codeScheme.languageCodes"
                                                                       [formControlName]="'prefLabel'"></app-localizable-input-with-language-indicator-for-all-languages>

      <dl *ngIf="editing">
        <dd>
          <div>
            <button id="open_terminology_modal_button"
                    type="button"
                    class="btn btn-sm btn-action mt-2"
                    *ngIf="editing"
                    (click)="openTerminologyModal()" translate>Get concept from Terminologies</button>
          </div>
        </dd>
      </dl>

      <app-inline-clipboard *ngIf="!editing && code.conceptUriInVocabularies && code.conceptUriInVocabularies.length > 0"
                            [label]="'Vocabularies URI' | translate"
                            [showAsLink]="true"
                            [value]="getConceptUri()"
                            [infoText]="'INFO_TEXT_VOCABULARIES_URI'"></app-inline-clipboard>
      <div *ngIf="editing && code.conceptUriInVocabularies && code.conceptUriInVocabularies.length > 0">
        <dl>
          <dt>
            <label>{{'Vocabularies URI' | translate}}</label>
            <app-information-symbol [infoText]="'INFO_TEXT_VOCABULARIES_URI'"></app-information-symbol>
          </dt>
          <dd>
            <a>
              <i [id]="'remove_concept_uri_in_vocabularies_link'"
                 class="fa fa-times"
                 (click)="removeConceptUriInVocabularies()"></i>
            </a>
            {{getConceptUri()}}
          </dd>
        </dl>
      </div>

      <div class="row" *ngIf="isSuperUser && editing">
        <div class="col-md-2" style="line-height: 45px; white-space: nowrap">
          <div class="checkbox">
            <input id="enforce_status_transition_rules_checkbox" type="checkbox" (click)="toggleEnforceTransitionRulesForSuperUserToo()">
            <label style="vertical-align: 5%; padding-left: 5px;" for="enforce_status_transition_rules_checkbox" translate>Enforce status transition rules</label>
          </div>
        </div>
      </div>

      <app-status-input id="code_status_input"
                        [formControlName]="'status'"
                        [originalStatus]="code.status"
                        [isSuperUser]="isSuperUser && freeSuperUserFromStatusTransitionRules"
                        [infoText]="'INFO_TEXT_CODE_STATUS'"></app-status-input>

      <app-localizable-textarea-with-language-indicator-for-all-languages [id]="'code_description_textarea'"
                                                                          [htmlIdentifierPrefix]="'code_description_textarea'"
                                                                          [label]="'Description' | translate"
                                                                          [infoText]="'INFO_TEXT_CODE_DESCRIPTION'"
                                                                          [parentElementsLanguageCodes]="codeScheme.languageCodes"
                                                                          [formControlName]="'description'"></app-localizable-textarea-with-language-indicator-for-all-languages>

      <app-localizable-textarea-with-language-indicator-for-all-languages [id]="'code_definition_textarea'"
                                                                          [htmlIdentifierPrefix]="'code_definition_textarea'"
                                                                          [label]="'Definition' | translate"
                                                                          [infoText]="'INFO_TEXT_CODE_DEFINITION'"
                                                                          [parentElementsLanguageCodes]="codeScheme.languageCodes"
                                                                          [formControlName]="'definition'"></app-localizable-textarea-with-language-indicator-for-all-languages>
    </div>

    <div class="col-5">


      <app-literal-input [id]="'code_shortname_input'"
                         [label]="'Short name' | translate"
                         [infoText]="'INFO_TEXT_CODE_SHORT_NAME'"
                         [formControlName]="'shortName'"
                         [restrict]="restricted"></app-literal-input>

      <app-code-input id="broader_code_input"
                      [label]="'Broader code' | translate"
                      [codeSchemes]="codeSchemes"
                      [showDetailLabel]="false"
                      [restricts]="[code.id]"
                      [infoText]="'INFO_TEXT_BROADER_CODE'"
                      [formControlName]="'broaderCode'"></app-code-input>

      <app-code-scheme-single-input id="subcodescheme_input"
                                    [label]="'Sub code list' | translate"
                                    [restricts]="[code.codeScheme.id]"
                                    [infoText]="'INFO_TEXT_SUBCODESCHEME'"
                                    [formControlName]="'subCodeScheme'"></app-code-scheme-single-input>

      <app-date-range-input id="code_validity_input"
                            [label]="'Validity' | translate"
                            [infoText]="'INFO_TEXT_CODE_VALIDITY'"
                            [formControlName]="'validity'"></app-date-range-input>

      <app-inline-clipboard [label]="'URI' | translate"
                            [value]="getCodeUri()"
                            [showAsLink]="false"
                            [infoText]="'INFO_TEXT_CODE_URI'"></app-inline-clipboard>

      <app-literal *ngIf="user"
                   [id]="'code_user'"
                   [label]="'Modified by' | translate"
                   [value]="user.getDisplayName()"
                   [infoText]="'INFO_TEXT_LAST_MODIFICATION_USER'"></app-literal>

      <app-literal [id]="'extension_last_modification'"
                   [label]="'Last modification' | translate"
                   [value]="code.modifiedDisplayValue"
                   [infoText]="'INFO_TEXT_CODE_LAST_MODIFICATION'"></app-literal>

      <app-external-references-input [id]="'code_ext_references_input'"
                                     [label]="'Links' | translate"
                                     [codeScheme]="codeScheme"
                                     [languageCodes]="codeScheme.languageCodes"
                                     [infoText]="'INFO_TEXT_CODE_EXTERNAL_REFERENCES'"
                                     [formControlName]="'externalReferences'"></app-external-references-input>

      <div>
        <div *ngFor="let extension of codeExtensionsFormArray.controls as FormArray; let i=index">
          <app-code-extension-membervalues-input [id]="'code_inline_extensions'"
                                                 [label]="getExtensionDisplayName(extension.value.id)"
                                                 [extension]="getExtension(extension.value.id)"
                                                 [extensionGroup]="extension">
          </app-code-extension-membervalues-input>
        </div>
      </div>
    </div>

    <div class="col-2">
      <app-editable-buttons [form]="form"
                            [entity]="code"></app-editable-buttons>
    </div>
  </div>

</form>
