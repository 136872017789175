<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading" class="content-box">
  <div class="main">
    <div>
      <h1 class="text-content-wrap">{{member.getDisplayName(languageService, translateService)}}</h1>
    </div>

    <div class="float-right">
      <div *ngIf='showMenu' ngbDropdown class="d-inline-block float-right ml-3" placement="bottom-right">
        <button class="btn btn-action" id="memberDropdown" ngbDropdownToggle translate>Choose action</button>
        <div ngbDropdownMenu aria-labelledby="memberDropdown">
          <button id="member_delete_button"
                  *ngIf='canDelete'
                  type="button"
                  class="dropdown-item"
                  (click)="delete()"
                  translate>Delete member</button>
        </div>
      </div>

      <app-content-language [languageCodes]="codeScheme.languageCodes"></app-content-language>
    </div>
  </div>

  <div class="row metabar">
    <div class="col-12">
      <div class="metaitem">
        <span translate>Registry</span><span>: </span>
        <span class="routedLink"
              (click)="navigateToRoute(extension.parentCodeScheme.codeRegistry.route)">{{extension.parentCodeScheme.codeRegistry.getDisplayName(languageService)}}</span>
      </div>
      <div class="metaitem">
        <span translate>Code list</span><span>: </span>
        <span class="routedLink"
              (click)="navigateToRoute(extension.parentCodeScheme.route)">{{extension.parentCodeScheme.getDisplayName(languageService)}}</span>
      </div>
      <div class="metaitem">
        <span translate>Extension</span><span>: </span>
        <span class="routedLink"
              (click)="navigateToRoute(extension.route)">{{extension.getDisplayName(languageService)}}</span>
      </div>
      <div class="metaitem" *ngIf="extension.parentCodeScheme.organizations.length > 0">
        <span translate>Organization</span><span>: </span>
        <span *ngFor="let organization of extension.parentCodeScheme.mainOrganizations; let last = last">{{organization.prefLabel | translateValue:true}}{{last ? '' : ' · '}}</span>
      </div>
    </div>
  </div>

  <ul ngbNav #nav="ngbNav" class="nav-tabs" (navChange)="onNavChange($event)">
    <li ngbNavItem="member_info_tab" id="member_info_tab">
      <a ngbNavLink>
        <span translate>INFORMATION</span>
      </a>
      <ng-template ngbNavContent>
        <app-member-information [member]="member"></app-member-information>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
