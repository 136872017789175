<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading" class="content-box">
  <div class="main">
    <div>
      <h1 translate>Create code</h1>
    </div>

    <div class="float-right">
      <app-content-language [languageCodes]="codeScheme.languageCodes"></app-content-language>
    </div>
  </div>

  <form [formGroup]="codeForm" #form="ngForm">

    <div class="row">
      <div class="col-lg-5 col-md-5">
        <app-localizable-literal [label]="'Code list' | translate"
                                 [value]="codeScheme.prefLabel"
                                 [infoText]="'INFO_TEXT_CODE_CODELIST_NAME'"></app-localizable-literal>

        <app-literal-input [id]="'code_value_input'"
                           [label]="'LABEL_TEXT_CODE_VALUE' | translate"
                           [required]="true"
                           [infoText]="'INFO_TEXT_CODE_VALUE'"
                           [formControlName]="'codeValue'"></app-literal-input>

        <app-localizable-input-with-language-indicator-for-all-languages [id]="'code_name_input'"
                                                                         [htmlIdentifierPrefix]="'code_name_input'"
                                                                         [label]="'Code name' | translate"
                                                                         [infoText]="'INFO_TEXT_CODE_NAME'"
                                                                         [parentElementsLanguageCodes]="codeScheme.languageCodes"
                                                                         [formControlName]="'prefLabel'"></app-localizable-input-with-language-indicator-for-all-languages>

        <dl>
          <dd>
            <div>
              <button id="open_terminology_modal_button"
                      type="button"
                      class="btn btn-sm btn-action mt-2"
                      (click)="openTerminologyModal()" translate>Get concept from Terminologies</button>
            </div>
          </dd>
        </dl>
        <div *ngIf="codeForm.controls['conceptUriInVocabularies'].value.toString().length > 0">
          <dl>
            <dt>
              <label>{{'Vocabularies URI' | translate}}</label>
              <app-information-symbol [infoText]="'INFO_TEXT_VOCABULARIES_URI'"></app-information-symbol>
            </dt>
            <dd>
              <a>
                <i [id]="'remove_concept_uri_in_vocabularies_link'"
                   class="fa fa-times"
                   (click)="removeConceptUriInVocabularies()"></i>
              </a>
              {{codeForm.controls['conceptUriInVocabularies'].value}}
            </dd>
          </dl>
        </div>

        <app-status-input id="code_status_input"
                          [formControlName]="'status'"
                          [infoText]="'INFO_TEXT_CODE_STATUS'"></app-status-input>

        <app-localizable-textarea-with-language-indicator-for-all-languages [id]="'code_description_textarea'"
                                                                            [htmlIdentifierPrefix]="'code_description_textarea'"
                                                                            [label]="'Description' | translate"
                                                                            [infoText]="'INFO_TEXT_CODE_DESCRIPTION'"
                                                                            [parentElementsLanguageCodes]="codeScheme.languageCodes"
                                                                            [formControlName]="'description'"></app-localizable-textarea-with-language-indicator-for-all-languages>

        <app-localizable-textarea-with-language-indicator-for-all-languages [id]="'code_definition_textarea'"
                                                                            [htmlIdentifierPrefix]="'code_definition_textarea'"
                                                                            [label]="'Definition' | translate"
                                                                            [infoText]="'INFO_TEXT_CODE_DEFINITION'"
                                                                            [parentElementsLanguageCodes]="codeScheme.languageCodes"
                                                                            [formControlName]="'definition'"></app-localizable-textarea-with-language-indicator-for-all-languages>


      </div>

      <div class="col-lg-5 col-md-5">
        <app-literal-input [id]="'code_shortname_input'"
                           [label]="'Short name' | translate"
                           [infoText]="'INFO_TEXT_CODE_SHORT_NAME'"
                           [formControlName]="'shortName'"></app-literal-input>

        <app-code-input id="broader_code_input"
                        [label]="'Broader code' | translate"
                        [codeSchemes]="codeSchemes"
                        [showDetailLabel]="false"
                        [infoText]="'INFO_TEXT_BROADER_CODE'"
                        [formControlName]="'broaderCode'"></app-code-input>

        <app-code-scheme-single-input id="subcodescheme_input"
                                      [label]="'Sub code list' | translate"
                                      [restricts]="[codeScheme.id]"
                                      [infoText]="'INFO_TEXT_SUBCODESCHEME'"
                                      [formControlName]="'subCodeScheme'"></app-code-scheme-single-input>

        <app-date-range-input id="code_validity_input"
                              [label]="'Validity' | translate"
                              [infoText]="'INFO_TEXT_CODE_VALIDITY'"
                              [formControlName]="'validity'"></app-date-range-input>

        <app-external-references-input [id]="'code_ext_references_input'"
                                       [label]="'Links' | translate"
                                       [codeScheme]="codeScheme"
                                       [languageCodes]="codeScheme.languageCodes"
                                       [infoText]="'INFO_TEXT_CODE_EXTERNAL_REFERENCES'"
                                       [formControlName]="'externalReferences'"></app-external-references-input>

        <div>
          <div *ngFor="let extension of codeExtensionsFormArray.controls as FormArray; let i=index">
            <app-code-extension-membervalues-input [id]="'code_inline_extensions'"
                                                   [label]="getExtensionDisplayName(extension.value.id)"
                                                   [extension]="getExtension(extension.value.id)"
                                                   [extensionGroup]="extension">
            </app-code-extension-membervalues-input>
          </div>
        </div>
      </div>

      <div class="col-2">
        <app-editable-buttons [form]="form"></app-editable-buttons>
      </div>

    </div>
  </form>
</div>
