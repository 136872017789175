<div *ngIf="uploading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!uploading">
  <div class="modal-header">
    <h4 class="modal-title strong">
      <a><i id="close_modal_link" class="fa fa-times" (click)="close()"></i></a>
      <span translate>Import codes to code list</span>
    </h4>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div ngbDropdown class="d-inline-block">
          <p translate>INFO_TEXT_IMPORT_CODES_MODAL_HELP_TEXT</p>
          <dl>
            <dt>
              <label for="file_format_dropdown_button" translate>File format</label>
            </dt>
            <dd>
              <button class="btn btn-dropdown" id="file_format_dropdown_button" ngbDropdownToggle>
                <span>{{format}}</span>
              </button>

              <div ngbDropdownMenu aria-labelledby="file_format_dropdown_button">
                <button id="excel_format_dropdown_button"
                        (click)="format = 'Excel'"
                        class="dropdown-item"
                        [class.active]="format === 'Excel'">
                  'Excel'
                </button>

                <button id="csv_format_dropdown_button"
                        (click)="format = 'CSV'"
                        class="dropdown-item"
                        [class.active]="format === 'CSV'">
                  'CSV'
                </button>
              </div>
            </dd>
          </dl>
        </div>

        <div class="form-group">
          <dl>
            <dt>
              <label for="fileupload_input" translate>File to be uploaded</label>
              <app-required-symbol></app-required-symbol>
            </dt>
            <dd>
              <input name="file" id="fileupload_input" type="file" (change)="onChange($event)"/>
            </dd>
          </dl>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div>
      <button id="upload_codes_file_button" [disabled]="!canSave()" type="button" class="btn btn-action"
              (click)="uploadCodesFile()" translate>Import</button>
      <button id="cancel_upload_button" type="button" class="btn btn-link" (click)="close()" translate>Cancel</button>
    </div>
  </div>
</div>
