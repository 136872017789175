<div *ngIf="loading">
  <app-ajax-loading-indicator></app-ajax-loading-indicator>
</div>

<div *ngIf="!loading">
  <form [formGroup]="extensionForm" #form="ngForm">

    <div class="row">
      <div class="col-5">
        <app-literal *ngIf="this.extension.parentCodeScheme"
                     [label]="'Code list' | translate"
                     [value]="extension.parentCodeScheme.getDisplayName(languageService)"
                     [infoText]="'INFO_TEXT_EXTENSION_CODE_LIST'"></app-literal>

        <app-literal [label]="'LABEL_TEXT_EXTENSION_ID' | translate"
                     [value]="extension.codeValue"
                     [infoText]="'INFO_TEXT_EXTENSION_ID'"></app-literal>

        <app-localizable-input-with-language-indicator-for-all-languages *ngIf="!isCodeExtension"
                                                                         [id]="'extension_name_input'"
                                                                         [htmlIdentifierPrefix]="'extension_name_input'"
                                                                         [label]="'Extension name' | translate"
                                                                         [infoText]="'INFO_TEXT_EXTENSION_NAME'"
                                                                         [parentElementsLanguageCodes]="extension.parentCodeScheme.languageCodes"
                                                                         [formControlName]="'prefLabel'"></app-localizable-input-with-language-indicator-for-all-languages>

        <app-literal [label]="'Extension type' | translate"
                     [value]="extension.propertyType.prefLabel | translateValue:false"
                     [infoText]="'INFO_TEXT_EXTENSION_TYPE'"></app-literal>

        <app-code-scheme-input *ngIf="allowCodeSchemes"
                               id="extension_code_scheme_input"
                               [infoText]="'INFO_TEXT_EXTENSION_CODE_LISTS'"
                               [label]="'Code list' | translate"
                               [parentCodeScheme]="extension.parentCodeScheme"
                               [formControlName]="'codeSchemes'"></app-code-scheme-input>

      </div>

      <div class="col-5">

        <div class="row" *ngIf="isSuperUser && editing">
          <div class="col-md-4" style="line-height: 45px; white-space: nowrap">
            <div class="checkbox">
              <input id="enforce_status_transition_rules_checkbox" type="checkbox" (click)="toggleEnforceTransitionRulesForSuperUserToo()">
              <label style="vertical-align: 5%; padding-left: 5px;" for="enforce_status_transition_rules_checkbox" translate>Enforce status transition rules</label>
            </div>
          </div>
        </div>

        <app-status-input id="extension_status_input"
                          [formControlName]="'status'"
                          [originalStatus]="extension.status"
                          [isSuperUser]="isSuperUser && freeSuperUserFromStatusTransitionRules"
                          [infoText]="'INFO_TEXT_EXTENSION_STATUS'"></app-status-input>

        <app-date-range-input id="extension_validity_input"
                              [label]="'Validity' | translate"
                              [infoText]="'INFO_TEXT_EXTENSION_VALIDITY'"
                              [formControlName]="'validity'"></app-date-range-input>

        <app-inline-clipboard [label]="'URI' | translate"
                              [value]="getExtensionUri()"
                              [showAsLink]="false"
                              [infoText]="'INFO_TEXT_URI'"></app-inline-clipboard>

        <app-literal *ngIf="user"
                     [id]="'extension_user'"
                     [label]="'Modified by' | translate"
                     [value]="user.getDisplayName()"
                     [infoText]="'INFO_TEXT_LAST_MODIFICATION_USER'"></app-literal>

        <app-literal [id]="'extension_last_modification'"
                     [label]="'Last modification' | translate"
                     [value]="extension.modifiedDisplayValue"
                     [infoText]="'INFO_TEXT_EXTENSION_LAST_MODIFICATION'"></app-literal>
      </div>

      <div class="col-2">
        <app-editable-buttons [form]="form"
                              [entity]="extension"></app-editable-buttons>
      </div>
    </div>
  </form>
</div>
